<template>
  <TheSidebar></TheSidebar>
  <section id="PageSearch" class="col-12 col-md-9 wrapper-text">
    <div class="alert alert-danger" role="alert" v-if="message !== ''">
      {{ message }}
    </div>
    <div class="card mb-3">
      <div class="card-header bg-color-primary-2 text-white text-center">
        جستجو پیشرفته
      </div>
      <div class="card-body">
        <form @submit.prevent="getFiltered(1)">
          <div class="row align-items-end">
            <div class="col-md-4 mb-3">
              <label class="form-label"
              >نوع نمایش<span class="text-danger ms-1">*</span></label
              >
              <select class="form-select" v-model="method">
                <option
                    v-for="method in method_by"
                    :key="method.type"
                    :value="method.type"
                    @click="getFilteredGenres(method.type)"
                    v-text="method.text"
                ></option>
              </select>
            </div>
            <div class="col-md-4 mb-3">
              <label class="form-label"
              >ژانر<span class="text-danger ms-1">*</span></label
              >
              <select class="form-select" v-model="genre">
                <option
                    v-for="genre in filteredGenres"
                    :key="genre.id"
                    :value="genre.id"
                >
                  {{ translatePersian(genre.name) }}
                </option>
              </select>
            </div>
            <div class="col-md-4 mb-3">
              <label class="form-label">تاریخ انتشار</label>
              <select class="form-select" v-model="year">
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
              </select>
            </div>
            <div class="col-md-4 mb-3">
              <label class="form-label">نمایش بر اساس</label>
              <select class="form-select" v-model="sort_by">
                <option
                    v-for="sort in sortBy"
                    :key="sort.type"
                    :value="sort.type"
                    v-text="sort.text"
                ></option>
              </select>
            </div>
            <div class="col-md-4 mb-3">
              <label class="form-label">میانگین امتیاز</label>
              <select class="form-select" v-model="vote_average">
                <option
                    v-for="i in 10"
                    :key="i"
                    :value="i"
                    v-text="'بالاتر از ' + i"
                ></option>
              </select>
            </div>
            <div class="col-md-4 mb-3">
              <button type="submit" class="btn btn-primary w-100">
                <i class="d-inline-flex align-middle me-1 mdi mdi-magnify"></i
                >جستجو کن
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div
        class="card"
        v-if="this.$store.state.getMovie.status.filteredShowLoading"
    >
      <div class="card-body">
        <div class="row wrapper-text">
          <template v-if="this.method === 'movie'">
            <div
                class="col-sm-6 col-md-4 col-lg-3"
                v-for="movie in filtered"
                :key="movie.id"
            >
              <CardViewMovie
                  :movie="movie"
                  :size="imageSizeMovie('w342')"
              ></CardViewMovie>
            </div>
          </template>
          <template v-if="this.method === 'tv'">
            <div
                class="col-sm-6 col-md-4 col-lg-3"
                v-for="movie in filtered"
                :key="movie.id"
            >
              <CardViewTv
                  :movie="movie"
                  :size="imageSizeMovie('w500')"
              ></CardViewTv>
            </div>
          </template>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <ThePagination
                :currentPage="this.$store.state.getMovie.pages.current"
                :totalPage="this.$store.state.getMovie.pages.totalPage"
                @pageChanged="showPageChangedFiltered"
            >
            </ThePagination>
            <p
                v-if="this.$store.state.getMovie.pages.current === 500"
                class="text-center text-danger mb-0"
            >
              حداکثر اطلاعات ارائه شده توسط API در حالت "درخواست بر اساس شماره
              صفحه" ۵۰۰ صفحه میباشد.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
        class="card"
        v-if="
        filtered.length > 0 &&
        !this.$store.state.getMovie.status.filteredShowLoading
      "
    >
      <div class="card-body">
        <Spinner/>
      </div>
    </div>
  </section>
</template>

<script>
import {defineAsyncComponent} from 'vue'

export default {
  name: "PageSearch",
  components: {
    'CardViewMovie': defineAsyncComponent(() =>
        import('@/components/cardView/cardViewMovie')
    ),
    'ThePagination': defineAsyncComponent(() =>
        import('@/components/cardView/cardViewPerson')
    ),
    'Spinner': defineAsyncComponent(() =>
        import('@/components/template/spinner')
    ),
    'CardViewTv': defineAsyncComponent(() =>
        import('@/components/cardView/cardViewTv')
    ),
    'TheSidebar': defineAsyncComponent(() =>
        import('@/components/TheSidebar')
    )
  },
  data() {
    return {
      method_by: [
        {
          type: "movie",
          text: "فیلم",
        },
        {
          type: "tv",
          text: "شبکه خانگی",
        },
      ],
      method: "",
      sort_by: "",
      sortBy: [
        {
          text: "محبوبیت صعودی",
          type: "popularity.asc",
        },
        {
          text: "محبوبیت نزولی",
          type: "popularity.desc",
        },
        {
          text: "تاریخ انتشار صعودی",
          type: "release_date.asc",
        },
        {
          text: "تاریخ انتشار نزولی",
          type: "release_date.desc",
        },
        {
          text: "میانگین امتیاز صعودی",
          type: "vote_average.asc",
        },
        {
          text: "میانگین امتیاز نزولی",
          type: "vote_average.desc",
        },
      ],
      year: "",
      genre: "",
      vote_average: "",
      message: "",
    };
  },
  computed: {
    filteredGenres() {
      return this.$store.state.getMovie.filteredGenres;
    },
    filtered() {
      return this.$store.state.getMovie.filtered;
    },
  },
  methods: {
    getFiltered(page) {
      if (this.method === "" || this.genre === "") {
        this.message = "لطفا نوع نمایش و ژانر انتخاب کنید.";
      } else {
        if (this.vote_average === "") {
          this.vote_average = 7;
        }
        if (this.sort_by === "") {
          this.sort_by = "popularity.asc";
        }
        if (this.year === "") {
          this.year = 2022;
        }
        let method = this.method;
        let sort_by = this.sort_by;
        let year = parseInt(this.year);
        let vote_average = parseInt(this.vote_average);
        let with_genre = this.genre;
        let query = `discover/${method}?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}&sort_by=${sort_by}&include_adult=false&include_video=false&page=${page}&year=${year}&vote_average.gte=${vote_average}&with_genres=${with_genre}`;
        this.$store.dispatch("getMovie/fetchDataPagination", {
          page: page,
          query: query,
          setName: "setFiltered",
        });
        this.message = "";
      }
    },
    getFilteredGenres(method) {
      let query = `genre/${method}/list?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setFilteredGenres",
      });
      this.$store.commit("getMovie/setResetFilter");
      this.genre = "";
      this.sort_by = "";
      this.year = "";
      this.vote_average = "";
    },
    showPageChangedFiltered(page) {
      this.getFiltered(page);
    },
  },
  unmounted() {
    this.$store.commit("getMovie/setResetAdvanceSearch");
  },
};
</script>

<style scoped></style>
